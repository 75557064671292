//
//   rev 22.05.17
//   websocket connection utilities wrapper
//
//   once connection establish, fire a window event: wsready
//
import * as utils from "./utilities.js";

let ws = false;
let wsconn;
let pingTimer;


export default class {

     constructor(ws_url, cbfunc = false) {

          wsconn = this;

          this.pingInterval = 60;    // 60 sec
          this.wsURL = ws_url;
          this.pingPayload = [];        // the payload to server while heartbeating
          this.enableReconnect = 1;     // reconnect automatically while server down

          wsconn.setup(cbfunc);
     }

     setup(cbfunc) {
          utils.log("connecting to", wsconn.wsURL);
          $(window).trigger("wsConnecting");
          ws = new WebSocket(wsconn.wsURL);

          ws.onopen = function (event) {
               utils.log("ws ready");

               wsconn.wsPing();
               $(window).trigger("wsReady");

               if (cbfunc) {
                    cbfunc(this);
               }

          };

          ws.onerror = function(event) {
               $(window).trigger({
                    type : "wsError",
                    wsdata : event
               });
               utils.log('WebSocket error: ', event);
               ws.close();
          };

          ws.onmessage = function(event) {

               // utils.log('received', event)

               var cmdobj = JSON.parse(event.data);
               // broadcast whoever need this function

               $(window).trigger({
                    type : "wsDataReceived",
                    wsdata : cmdobj
               });
          };

          ws.onclose = function(event) {
               utils.log("socket closed");
               if (wsconn.enableReconnect) {
                    wsconn.wsReconnect();
                    wsconn.wsPing();
               }
          }


     }

     wsClose() {
          $(window).trigger("wsClose");
          wsconn.enableReconnect  = 0;
          clearInterval(pingTimer);
          ws.close();
     }

     wsReconnect() {
          utils.log("reconnecting in 3sec...");
          $(window).trigger("wsReConnecting");
          setTimeout(function(){
               wsconn.setup();
          }, 3000);

     }

     wsPing() {
          clearInterval(pingTimer);
          if (ws.readyState == 1) {
               pingTimer = setInterval(function(){
                    wsconn.wsSendData(wsconn.pingPayload);
                    // utils.log("check server alive");
               }, this.pingInterval * 1000);
          }
     }

     wsSendData(data){
          if (ws.readyState == 1) {
               // encode in JSON
               ws.send(JSON.stringify(data));
               utils.log("send ...", data);
          } else {
               utils.log("ws not ready")
          }
     }

}
