
//   rev 22.06.16
//   define layout view for each available verb


import * as layoutControl from "./layoutControl.js";
import * as layouts from "./layoutTemplates.js";       // stores all kind of layout format

import * as pages from '../verbs/*/settings.js';       // load all verb settings

export const layoutTemplates = layouts;

export const page_manifest_item_tpl = {
     title : "",                  // page title
     layout : layouts.base,         // use what layout
     contents : false          // how to handle the data (entry point)
};

//   rev 22.06.25
//   load page settings from verb page folder
//
export function getPageManifest(verb) {

     var verb_pm = {};
     let pm = {};

     if (verb) {
          if (pages[verb] !== undefined) {
               verb_pm[verb] = pages[verb].page_manifest;
          }

          pm = page_manifest;
          $.extend(pm, verb_pm);

          return pm[verb];
     }
     return undefined;
}


// for no present of verb page module, i.e. inline content or EXTERNAL_URL
export const page_manifest = {
          test : {
               title : "Quick Code Test",       // page title
               layout : layouts.base,                    // use what layout
               contents : function(verb, e) {                // how to handle the data (entry point)
                              // demo show how to customize
                              //   by default
                              if (e.result) {
                                   content = "Result : "+ JSON.stringify(e.data);
                              } else {
                                   content = "Failed query";
                              }

                              return layoutControl.renderLayoutWidget({
                                   header : "Output",
                                   contents : "<div>" + content + "</div>"
                              }, layouts.widgetCard);

                         }
          },
          userCreateAdmin : {
               title : "Ping Test Well defined",       // page title
               layout : layouts.base,                    // use what layout
               contents : function(verb, e) {                // how to handle the data (entry point)
                              // demo show how to customize
                              //   by default
                              return "done";
                         }
          },
          viewUIKit : {
               title : "UI Kit",
               layout : layouts.base,
               contents : "EXTERNAL_URL"
          },
          viewWorkerPanel : {
               title : "NR Workers Control",
               layout : layouts.base,
               contents : "EXTERNAL_URL"
          }
     }
