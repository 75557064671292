import * as utils from "../../js/utilities.js";
import * as api from "../../js/api_conn.js";
import moment from "moment";

import tpl from 'bundle-text:./component_listerBGTasks.tpl';

export {
     render
}

let pool;      // container for this component

let maxItemDisplay = 10;
let statusText = {
          0 : "READY",
          1 : "<i class='icon-cog2 spinner'></i> WORKING",
          2 : "COMPLETED",
          3 : "<span class='badge badge-danger'>CANCELLED</span>",
          4 : "<span class='badge badge-teal'>ON HOLD</span>",
          5 : "Archived",
          6 : "<span class='badge badge-info'>SCHEDULED</span>"
     };

const placeholder_img = "/assets/images/placeholder.jpg";

const item_tpl = `<li class="media">
                    <div class="mr-3 position-relative">
                         <a href="#" class="btn bg-transparent border-pink text-pink rounded-pill border-2 btn-icon">
                              <i class="icon-statistics"></i></a>
                    </div>

                    <div class="media-body">
                         <div class="d-flex justify-content-between">
                              <div class="media-title event-title">--</div>
                              <span class="font-size-sm text-muted event-timestamp"></span>
                         </div>
                    </div>
               </li>`


const task_tpl = `
                    <div class="card card-body">

                         <div class="d-flex mb-2 align-items-start">
                              <div>
                                   <h6 class="font-weight-semibold mb-0 task-title"></h6>
                                   <span class="text-muted task-starttime"></span>
                              </div>
                    	     <div class="list-icons ml-auto task-action-buttons">
                                     <a class="icon-pause task-action-pause" task-action="pause"></a>
                                     <a class="icon-play3 task-action-play" task-action="play"></a>
                                     <a class="icon-cross3 task-action-stop" task-action="stop"></a>
                    		</div>
                         </div>

					<div class="progress mb-2" style="height: 0.125rem;">
						<div class="progress-bar bg-indigo" style="width:0%">
							<span class="sr-only task-percent"></span>
						</div>
					</div>
				      <div class="task-progress">
		                        <span class="float-right task-percent"></span>
                                  <span class="task-status"></span>
	                     </div>
				</div>`;

const itemClass = "eventitems";
const taskClass = "taskitems";

// component entry point
//   if no cbfunc, means, widget control render stop looking for nested pj-widget
function render(target) {

     pool = $(target);
     $(target).html(tpl);
     // pool.remove();  // disable visible element

     // definitions
     initEvents();
     initElements();

}



function getTotalMessageItem() {
     return pool.find(`.${itemClass}`).length;
}

function updateMessageTimeStamp() {
     pool.find(`.${itemClass}`).trigger("refreshtimestamp");
}

function refresh() {
     updateMessageTimeStamp();
     onTaskStatusChange();
}

function onQuickActionTab(ctx) {
     refresh();
}

// setup event listener
// register pjEvents
function initEvents(){

     // global event
//     $(window).on("usersOnlineStatusChanged", (e) => {initOnlineUserList();});

     utils.registerPJEvent({
          onQuickActionTab : onQuickActionTab,
          onTaskStatusChange : onTaskStatusChange
     });

     // init event listener
}


function initElements() {
     // if any
}

function onTaskStatusChange(){

     // utils.log("task status changed");

     api.doQuery("allTasksStatus", {}, function(res){
         // utils.log(res);

          var totaltask = "";

          if (res.result) {

               // fire an event in order to avoid multple query to server while status changing
               utils.triggerPJEvent("taskStatusInfoUpdate", res.data);

               tasklist = res.data;
               totalrunning = 0;

               for(var idx in tasklist) {
                    var d = tasklist[idx];
                    totalrunning += (d.status == 1 ? 1 : 0 );
                    refreshTaskItem(d);
               }
               // update badge indicator
               totaltask = totalrunning > 0 ? totalrunning : "";               
          } 

          $(".nav-sidemenu-toggle-button .badge").text(totaltask);


     })


}

//   rev 22.08.12
//   res : { title, text}
function addItem(res) {

     var currtime = moment();
     var tpl = $(item_tpl).clone();

     tpl.data("timestamp", moment());
     tpl.addClass(itemClass);

     tpl.on("refreshtimestamp", (e) =>{
          var itemts = $(e.target).data("timestamp");
          var ts = moment(itemts, "hhmm").fromNow();
          $(e.target).find(".event-timestamp").html(ts);
     })

     tpl.find(".event-timestamp").html(currtime.format());
     tpl.find(".event-title").html(`<b>${res.title}</b>`);
     tpl.find(".media-body").append(`${res.text}`);

     if (getTotalMessageItem() >= maxItemDisplay) {
          pool.find("ul").children("li").last().remove();
     }

     pool.find("ul").prepend(tpl);
     refresh();
}

//   handle task event function
function onTaskEvent(e) {

     var taskitem =  $(e.target).closest(".task-items");
     var res = taskitem.data("res");
     var action = $(e.target).closest("a").attr("task-action");

     var actionlbl = {
          pause : "Suspending...",
          stop : "Cancelling",
          play : "Resuming"
     }

     var payload =  {
          taskSN : res.sn,
          action : action
     }

     // utils.log(payload);

     api.doQuery("taskControl", payload, (res) => {

          // utils.log(res);
          if (res.result) {
               taskitem.find(".task-status").html(actionlbl[action]);
          }
     });
}


//   rev 22.08.12
//   res : { title, text}
function refreshTaskItem(res) {

     var targetCls = taskClass + res.sn;
     var percent = Math.round( ((res.progresstotal - res.progressleft) / res.progresstotal) * 1000) / 10;
     var tpl;

     var isNewItem = !(pool.find(`.task-list .${targetCls}`).length > 0);

     if (isNewItem) {
          tpl = $(task_tpl).clone();
     } else {
          tpl = pool.find(`.task-list .${targetCls}`);
     }

     var starttime = res.timestart ? `Started at ${res.timestart}` : "Never started";

     tpl.find(".task-action-play").hide();
     tpl.find(".task-action-stop").show();
     tpl.find(".task-action-pause").show();

     switch (res.status) {
          case 0:
               if (res.timestart) {
                    starttime = `Started at ${res.timestart}`;
               } else {
                    starttime = `Scheduled on <b>${res.timeenable}</b>`;
                    res.status = 6;    // added for display
               }
               break;
          case 1:
               tpl.find(".task-action-pause").show();
               tpl.find(".task-action-play").hide();
               break;
          case 4:   // pause
               tpl.find(".task-action-pause").hide();
               tpl.find(".task-action-play").show();

               if (res.timestart) {

               } else {
                    starttime = `Scheduled on ${res.timeenable}`;
               }
               break;
          case 3:
          case 2:
               // if this is completed
               tpl.find(".task-action-play").hide();
               tpl.find(".task-action-stop").hide();
               tpl.find(".task-action-pause").hide();

               setTimeout(()=>{
                    $(tpl).fadeOut("fast", ()=>{
                         $(tpl).remove();
                    });
               }, 6000)

               break;
          default:
     }


     tpl.find(".task-percent").html(`${percent}%`);
     tpl.find(".progress-bar").css("width", percent + "%");
     tpl.find(".task-status").html(`${statusText[res.status]}`);
     tpl.find(".task-starttime").html(starttime);

     if (isNewItem) {
          tpl.find(".task-title").html(`<b>${res.title}</b>`);
          tpl.addClass(taskClass + res.sn).addClass("task-items");
          tpl.data("res", res);

          // create action event
          tpl.find(".task-action-buttons a").on("click", onTaskEvent);
          pool.find(".task-list").prepend(tpl);

     }


     // refresh();
}
