
// include all componsets
import * as onlineUsers from "./components/nav_onlineUsers.js";
import * as currentUserMenu from "./components/nav_currentUserMenu.js";
import * as userActionsPanel from "./components/panel_userActions.js";
import * as userChatBox from "./components/component_userChatBox.js";
import * as listerSystemEvents from "./components/component_listerSystemEvents.js";
import * as listerBGTasks from "./components/component_listerBGTasks.js";

export {
     render, refresh
}

const mapper = {
     onlineUsers,
     currentUserMenu,
     userActionsPanel,
     userChatBox,
     listerBGTasks,
     listerSystemEvents
}

//   rev 22.07.04
//   refresh specific compoonent layout once
//   comp_name : the name of the component
//   res : resources, pass-in parameters
function refresh(comp_name, res) {

     var elm =  $(`.pj-widgets[payload=${comp_name}]`);

     if (elm.length > 0){
          $(elm).trigger("refresh", [res]);
     }

}

// render all layout widgets once
//
function render(cbfunc, targetContainer) {

     var pool = $(".pj-widgets");

     if (targetContainer) {
          pool = $(targetContainer).find(".pj-widgets");
     }

     $.each(pool, function(idx, elm) {
          var target = $(elm);
          var widgetName = target.attr("payload");

          // assign dom event
          target.off("refresh").on("refresh", (evt, res) => {
               mapper[widgetName].refresh(res);
          });

          mapper[widgetName].render(elm, (rendered_elm) => {

               // check if there is any pj-widget in target
               var pj_wdgt = $(target).find(".pj-widgets");

               if (pj_wdgt.length > 0) {
                    render(cbfunc, target);
               }
          });
     });

     if (cbfunc){
          cbfunc();
     }

}
