// handling all login function

import * as config from "./config.js";
import * as utils from "./utilities.js";
import * as api from "./api_conn.js";

import * as layoutControl from "./layoutControl.js";

import lang from "./ui_lang.js";


import html_login from 'bundle-text:../views/modal_login.tpl';

export  {
     verification,
     registerSession,
     parseWSMessage,
     doSignOut,
     sendMessage
}

let ws;                  // active websocket connection
let timer_wait_checkin;  // timer for wating
let timer_wait_approval; // time for approval

let ticker_timer;

const max_wait_checkin_time = 5;       // max waiting check in time in second;
const max_wait_approval_time = 15;      // max waiting for other approval

const loginPanelelm = "#modal-login";


//   rev 22.08.02
//   handling messaging inter user
//   send text message to other party
//   payload {
//        to : "everyone" || sessSN
//        msg : (str) ...
//
//   }
function sendMessage(payload, cbfunc) {
     var userinfo = utils.getItem("userinfo");
     if (userinfo){

          var payload = {
               cmd : "message",
               payload : {
                    to : payload.to,
                    fm : userinfo.sessSN,
                    data : payload.message
               }
          };
          ws.wsSendData(payload);
          if (cbfunc) {
               cbfunc();
          }
     }
}





function verification() {
     updateUserStatus();

}

function doSignOut() {

     utils.log("proceed logout");
     api.doQuery("userSignOut", {}, function(e){
          utils.log("logout done", e);
          updateUserStatus();
     }) ;
}

//
//  procedure after login
//
function loginResult(e, verb){

     // utils.log(e)

     if (e.result) {
          // if credentials ok, proceed checkin
          utils.setItem("userinfo", e.data);
          registerSession(ws);
     } else {
          clearUserInfo();
          showLoginFailMessage({
               topic : "Access Denied",
               body : e.message
          });
          updateUserStatus();
     }

}


// msg : {topic , body}
function showLoginFailMessage(msg){

     var t = $(loginPanelelm).find(".message-login-error");

     t.removeClass("hide");
     t.find(".message-topic").html(msg.topic);
     t.find(".message-body").html(msg.body);

     // clear password field
     t.find("#inputPassword").val("");
}


function action_doPost(elm){


     var dataObj = [];
     var payload = {};

     var verb = $(this).attr("data-verb");
     var cbfunc = $(this).attr("data-cbfunc");

     if (verb !== "") {

          dataObj = $(this).attr("data-binding").split(",");

          for (idx in dataObj){
               var fd = dataObj[idx];
               var fieldname = $("#"+fd).attr("payload-field");
               var fieldvalue = $("#"+fd).val();
               payload[fieldname] = fieldvalue;
          }

          utils.loaderBlock(true);
          api.doQuery(verb, payload, function(e){
//              queryResult[0].innerHTML = JSON.stringify(e, null, 4);

               // this result means verb and token ok
               try {
                    eval(cbfunc + "(e, verb)");
               } catch (err) {
                    utils.log(cbfunc,"not defined :", err);
               }

          })
     }
}


//   ws message is handling from auth
function parseWSMessage(e){

     var payload = e.wsdata;
     var cmd = payload.cmd;
     var data = payload.data;

     switch (cmd) {
          case "checkin_result":
               switch (data.result) {
                    case 1:
                         // authroization success
                         // alert("Check in Done!");

                         // update verified
                         var userInfo = utils.getItem("userinfo");
                         userInfo.verified = 1
                         utils.setItem("userinfo", userInfo);

                         updateUserStatus();

                         clearTimeout(timer_wait_checkin);
                         utils.log("check in Done");
                         utils.loaderBlock(false);

                         $(window).trigger({
                              type : "authUserCheckInCompleted",
                              payload : {}
                         });

                         break;
                    case 2:   // awatinng authorizing
                         // alert("Awainting authroization");

                         clearTimeout(timer_wait_checkin);
                         utils.loaderBlock(true);
                         timer_wait_checkin = setTimeout(function(){
                              clearUserInfo();
                              updateUserStatus();
                              showLoginFailMessage({
                                   topic : "Login Approval Failed",
                                   body : "No approval received from other session"
                              });
                         }, max_wait_approval_time * 1000);

                         $(window).trigger({
                              type : "authUserAwaitingApproval",
                              payload : {}
                         });

                         break;
                    default:
                         utils.log("Check in failed");
                         clearUserInfo();
                         updateUserStatus();
                         showLoginFailMessage({
                              topic : "Check-in Failed",
                              body : "Session registration failure"
                         });
               }
               break;
          case "event":
               switch (data.action) {
                    case "forcelogout":
                         clearTimeout(timer_wait_checkin);
                         clearUserInfo();
                         updateUserStatus();
                         // what reason ?
                         switch (data.message) {
                              case "rejected":
                                   showLoginFailMessage({
                                        topic : `${lang.event_auth_rejected_topic}`,
                                        body : `${lang.event_auth_rejected_body}`
                                   });
                                   break;
                              case "userRevoke":
                                   // revoke or change of user status
                                   showLoginFailMessage({
                                        topic : `${lang.event_user_revoke_topic}`,
                                        body : `${lang.event_user_revoke_body}`
                                   });
                                   break;
                                   // do nothing
                              default:
                                   if (data.message) {
                                        showLoginFailMessage({
                                             topic :`${lang.event_kickout_topic}`,
                                             body : `${lang.event_kickout_body} : ${data.message}`
                                        });
                                   }
                         }
                         // alert("logout!");

                         break;
                    case "do_approval":
                         authorizeSession(data);
                         break;
                    case "do_register":
                         registerSession(ws);
                         break;
                    case "do_alivecheck":
                         replyAliveCheck(data);
                         break;
                    case "close_connection":
                         ws.wsClose();
                         utils.log("close connection recevied");
                         break;
                    case "do_refreshTaskEvent":   // request refresh task status update
                         utils.triggerPJEvent("onTaskStatusChange");
                         break;
                    case "do_completedTaskEvent":   // trigger when a bg task completed
                         utils.triggerPJEvent("onTaskCompleted");
                         break;                         
                    case "do_systemDisabled":   // trigger when system is locked
                         utils.triggerPJEvent("onSystemStatusChange", { locked : true});
                         break;                         
                    case "do_systemEnabled":   // trigger when system is unlocked
                         utils.triggerPJEvent("onSystemStatusChange", {locked : false });
                         break;                                                  
                    case "userOnline":
                         $(window).trigger("usersOnlineStatusChanged");
                         break;
                    default:
               }
               break;

          case "message":
               // text message incoming
               utils.triggerPJEvent("onUserMessage",data);
               break;
          default:
     }
     // updateUserStatus();

     // utils.log("WS receveid:", payload);

}

//   rev 22.06.09
//   check if user require login
//   display user status on ui
//
function updateUserStatus(){

     var userinfo = utils.getItem("userinfo");
     var p = {};

     if (userinfo){
          action_LoginPanel('hide');
          p = userinfo.userinfo;
     } else {
          action_LoginPanel();
     }

     $(window).trigger({
          type : "authUserInfoUpdated",
          payload : p
     });

}

function action_LoginPanel(sw) {

     switch(sw) {
          case "hide":
               $(loginPanelelm).modal('hide');
               break;

          default:
               if ($(loginPanelelm).length == 0){


                    $("body").append(html_login);

                    // render all pj-layout element
                    layoutControl.renderPJelements("body");

                    $(".doPost").on("click", action_doPost);
                    $(loginPanelelm).modal({
                         backdrop : 'static'
                    }).on('hidden.bs.modal', (e) => {
                         // utils.log("modal hidden");
                         $(loginPanelelm).modal('dispose').remove();
                    }).on('shown.bs.modal', (e) => {
                         // bind enter
                         $(loginPanelelm).on("keypress", (e1) => {
                              if (e1.keyCode == 13) {
                                   e1.preventDefault();
                                   $(loginPanelelm).find("#buttonLogin").click();
                              }
                         });
                    });

               } else {
                    // utils.log("exists!!!");
               }


     }
}



// remove all userinfo in local
function clearUserInfo() {

     utils.log("logout");
     utils.setItem("userinfo");
     utils.loaderBlock(false);
}


// reply server connection verification
function replyAliveCheck(d){

     var userinfo = utils.getItem("userinfo");

     if (userinfo){

          var payload = {
               cmd : "control",
               payload : {
                    action : "aliveCheck",
                    data : d.message
               }
          };

          ws.wsSendData(payload);
     }
}

//   rev 22.06.11
//   handling granting access requests
//
function authorizeSession(d) {

     if (d) {

          var targetInfo = d.message;

          if (targetInfo) {

               clearInterval(timer_wait_approval);
               ticker_timer = 1;
               timer_wait_approval = setInterval(function(){
                    var t = max_wait_approval_time - ticker_timer;
                    if (t > 0) {
                         ticker_timer += 1;
                         $("H1.auth-timer-countdown").text(t);
                    } else {
                         clearInterval(timer_wait_approval);
                         bootbox.hideAll();
                    }
               }, 1000);

               // prompt box
               bootbox.dialog({
                    message: `${lang.event_grantaccess_message} ${targetInfo.ipaddress}`,
                    title: `${lang.event_grantaccess_title} <H1 class="auth-timer-countdown">${max_wait_approval_time}</H1>`,
                    closeButton: false,
                    buttons: {
                         success: {
                              label: `${lang.event_grantaccess_approve_button}`,
                              className: 'btn-success',
                              callback: function() {
                                   var payload = {
                                        activateCode : d.result,
                                        state : "approved"
                                   };

                                   api.doQuery("userSessionAuthorize", payload, function(ctx){
                                        // var ctx = e.data;
                                        // if activation ok
                                        if (ctx.result) {
                                             // send notification to user
                                             var payload = {
                                                  cmd : "notifysession",
                                                  sessSN : ctx.data,
                                                  action : "do_register"

                                             };
                                             ws.wsSendData(payload);
                                        }
                                   })
                               }
                           },
                           danger: {
                               label: `${lang.event_grantaccess_reject_button}`,
                               className: 'btn-danger',
                               callback: function() {
                                    var payload = {
                                         activateCode : d.result,
                                         state : "rejected"
                                    };

                                    api.doQuery("userSessionAuthorize", payload, function(ctx){
                                         //var ctx = e.data;
                                         // if activation ok
                                         if (ctx.result) {
                                              // send notification to user
                                              var payload = {
                                                   cmd : "notifysession",
                                                   sessSN : ctx.data,
                                                   action : "rejected"
                                              };
                                              ws.wsSendData(payload);
                                         }
                                    })

                              }
                         },
                         ignore: {
                            label: `${lang.event_grantaccess_ignore_button}`,
                            className: 'btn-default',
                            callback: function() {
                                 // do nothing
                            }
                       }
                    }
               });

          }
     }

}




//
// rev 22.05.17
// register session by saved userInfo
//
function registerSession(active_ws){

     clearTimeout(timer_wait_checkin);
     if (active_ws) {

          ws = active_ws;
          var userinfo = utils.getItem("userinfo");

          if (userinfo){
               // sent heartbeat info
               ws.pingPayload ={
                    cmd : "ping",
                    sessSN : userinfo.sessSN
               };

               // send wskey to register session
               var payload = {
                    cmd : "checkin",
                    wskey : userinfo.wskey
               };
               ws.wsSendData(payload);

               timer_wait_checkin = setTimeout(function(){
                    utils.log("check in timeout");
                    clearUserInfo();
                    updateUserStatus();
                    showLoginFailMessage({
                         topic : `${lang.event_checkin_timeout_topic}`,
                         body : `${lang.event_checkin_timeout_body}`
                    });


               }, max_wait_checkin_time * 1000);

          } else {
               utils.log("empty userInfo");
               updateUserStatus();
          }

     } else {
          utils.log("WS not ready");
     }

}
