import * as utils from "../../js/utilities.js";
import * as api from "../../js/api_conn.js";


import tpl from 'bundle-text:./panel_userActions.tpl';

export {
     render
}

let pool;      // container for this component
let quickActionMenu;

// component entry point
function render(target, cbfunc) {

     pool = $(target);
     pool.html(tpl);

     // definitions
     quickActionMenu = $("#panel_tabs");

     initEvents();

     if (cbfunc) cbfunc(tpl);     

}

// setup event listener
// register pjEvents
function initEvents(){

     utils.registerPJEvent({
          openQuickActionMenu : onOpenQuickActionMenu
     });


     pool.find("ul.nav-tabs a[data-toggle=tab]").on("click", (e) => {

          var id = $(e.target).attr("id");
          // fire pj event
          utils.triggerPJEvent("onQuickActionTab", {
               tab : id
          });
     })

     quickActionMenu.on('shown.bs.modal', () => {
          utils.setAppProperty("quickActionMenuStatus", "shown");
     }).on('hidden.bs.modal', () => {
          utils.setAppProperty("quickActionMenuStatus", "hidden");
     });
}


//   handling pjevent : openQuickActionMenu
//   ctx : { tab : (str) }, i.e. chat-dialog, destinate whic tab open
//
function onOpenQuickActionMenu(ctx){

     if (ctx.tab) {
          openQuickActionMenu("#" + ctx.tab);
     }

}

function openQuickActionMenu(tabName){
     quickActionMenu.modal('show').find(tabName).click();
}
