import tpl from 'bundle-text:./nav_currentUserMenu.tpl';
import * as utils from "../utilities.js";

export {
     render,
     refresh
}

let container;

function render(target) {
     container = target;
     $(target).html(tpl);
}


// refresh user menu context
function refresh(menuCtx){

     var pool = $(container).find(".usermenu-dropdown");
     var menuitem = "";

     pool.find(".pj-menu-action").remove();

     $.each(menuCtx, (verb, ctx) => {

          var title = ctx.title;
          menuitem += `<a href="#" class="dropdown-item pj-menu-action" pj-verb="${verb}">${title}</a>`;

     });

     pool.prepend(menuitem);

     console.log("user menu refresh", menuCtx);

}
