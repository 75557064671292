import * as utils from "../../js/utilities.js";
import * as api from "../../js/api_conn.js";
import moment from "moment";

import tpl from 'bundle-text:./component_listerSystemEvents.tpl';

export {
     render
}


let pool;      // container for this component
let maxItemDisplay = 10;  // max item display

const placeholder_img = "/assets/images/placeholder.jpg";

const item_tpl = `     <div class="list-feed-item border-light ">
                         <div class="text-muted font-size-sm mb-1 event-timestamp">--</div>
                         <span class="event-text"></span>
                    </div>`

const itemClass = "systemeventitems";

// component entry point
//   if no cbfunc, means, widget control render stop looking for nested pj-widget
function render(target) {

     pool = $(target);
     $(target).html(tpl);
     // pool.remove();  // disable visible element

     // definitions
     initEvents();
     initElements();

}



function run(){

     setInterval(()=>{

               var res = {
                    text : "good to go!!!"
               };

               addItem(res);
               // updateMessageTimeStamp()

          }, 10000);

}


function getTotalMessageItem() {
     return pool.find(`.${itemClass}`).length;
}

function updateMessageTimeStamp() {
     pool.find(`.${itemClass}`).trigger("refreshtimestamp");
}

function refresh() {
     updateMessageTimeStamp();
}

// setup event listener
// register pjEvents
function initEvents(){

     // global event
//     $(window).on("usersOnlineStatusChanged", (e) => {initOnlineUserList();});

     utils.registerPJEvent({
          onQuickActionTab : onQuickActionTab
     });

     // init event listener
}

function onQuickActionTab(ctx) {
     utils.log("refresh timestamp");
     refresh();
}

function initElements() {
     // if any
}


//   rev 22.08.12
//   res : { title, text}
function addItem(res) {

     var currtime = moment();
     var tpl = $(item_tpl).clone();
     var text = `${res.text}`;

     tpl.data("timestamp", moment());
     tpl.addClass(itemClass);

     tpl.on("refreshtimestamp", (e) =>{
          var itemts = $(e.target).data("timestamp");
          var ts = moment(itemts, "hhmm").fromNow();
          $(e.target).find(".event-timestamp").html(ts);
     })

     tpl.find(".event-timestamp").html(currtime.format());
     tpl.find(".event-text").html(text);

     if (getTotalMessageItem() >= maxItemDisplay) {
          pool.find(".list-feed").children(".list-feed-item").last().remove();
     }

     pool.find(".list-feed").prepend(tpl);
     refresh();
}
