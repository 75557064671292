import "../css/styles.scss";

import _ from 'lodash';
import moment from 'moment';

/* import * as bootstrap from 'bootstrap';*/

import * as config from "./config.js";
import * as utils from "./utilities.js";
import wsconn from "./wsconn.js";

// for inter communication functions
// import intercomm from "./js/intercomm.js";

import * as auth from "./auth.js";
import * as dashboard from "./dashboard.js";

import * as widgetControl from "./widgetControl.js";
import * as layoutControl from "./layoutControl.js";

let ws;


$(function(){

     // Initialize module
     // ------------------------------
     app_init();


});



// this once happen once while the app started
function app_init() {

     initEventListeners();
     initWsConnection();
     initPJEvents();
     initLayouts();
     initActions();

}


// setup app layouts
function initLayouts(){

     widgetControl.render();
     layoutControl.renderPJelements("body");
}


// setup ws connection
function initWsConnection(){
     wsURL = config.wsURL;
     ws = new wsconn(wsURL);
}


// setup basic PJEvents
function initPJEvents(){

     utils.registerPJEvent({
          sendUserMessage : auth.sendMessage
     });

}

//   rev 22.08.02
//
//   init all base system events attached to window
//
function initEventListeners(){

     var evts = {
          wsReady: function(){
               utils.log("connected");
               auth.registerSession(ws);
               utils.loaderBlock(false);
          },
          wsConnecting: function(){
               utils.loaderBlock(true);
          },
          wsDataReceived : auth.parseWSMessage,
          authUserInfoUpdated : dashboard.refresh,

          // this is special event only for PJIO
          // handing all pjEvent trigger from utils.triggerPJEvent
          //   i.e. triggerPJEvent("fireMessage", {param1 : A, param2 : B}, cbfunc)
          _pjMessageEvent : pjEventsHandler
          //authUserCheckInCompleted: dashboard.setup
     }

     for (var k in evts) {
          $(window).off(k).on(k, evts[k]);
     }
}

//   rev 22.08.02
//   handling all pjEvent registered from module
//
function pjEventsHandler(e, pjEventName, params, cbfunc){

     var pjevts = window._pjEvents || {};
     var ctx_array = pjevts[pjEventName];

     if (ctx_array !== undefined) {
          for (var idx in ctx_array) {

               var ctx = ctx_array[idx];

               if (cbfunc !== undefined) {
                    cbfunc(ctx(params));
               } else {
                    ctx(params);
               }
          }
     } else {
          utils.log("PJ EVENT", pjEventName, "NOT FOUND");
     }

}

//   rev 22.08.02
//   handling all events fire from pj-action class
//   in most case, they are ui element, like button, click or any clickable item
//   ie. <a class='pj-action' pj-payload='evts_Name'>
//
function initActions() {

     var evts = {
               signout : auth.doSignOut
          };

     $.each(evts, function(evt, func){
          var elm = $(".pj-action[pj-payload="+evt+"]");
          if (elm.length > 0) {
               elm.unbind("click").bind("click", func);
          }
     });

}
