import * as utils from "../../js/utilities.js";
import * as api from "../../js/api_conn.js";

import tpl from 'bundle-text:./component_userChatBox.tpl';

export {
     render
}


let pool;      // container for this component

let chatDialog;
let chatMsgBox;

let targetAudience;

const placeholder_img = "/assets/images/placeholder.jpg";

const chat_bubble_me_tpl = `<li class="media media-chat-item-reverse">
                                   <div class="media-body">
                                        <div class="media-chat-item chat-message-text"></div>
                                        <div class="font-size-sm text-muted mt-2 chat-message-timestamp"></div>
                                   </div>
                                   <div class="ml-3"><a href="#"></a></div>
                              </li>`;

const chat_bubble_private_tpl = `<li class="media">
     						<div class="mr-3"><a href="#"></a></div>
							<div class="media-body">
								<div class="media-chat-item media-chat-private-item chat-message-text"></div>
								<div class="font-size-sm text-muted mt-2 chat-message-timestamp"></div>
							</div>
						</li>`

const chat_bubble_public_tpl = `<li class="media ">
     						<div class="mr-3"><a href="#"></a></div>
							<div class="media-body">
								<div class="media-chat-item media-chat-public-item chat-message-text"></div>
								<div class="font-size-sm text-muted mt-2 chat-message-timestamp"></div>
							</div>
						</li>`

const chat_bubble_system_tpl = `<li class="media" style="margin-right:0px;">
     						<div class="mr-3"><a href="#"><i class="icon-notification2 icon-2x"></i></a></div>
							<div class="media-body">
								<div class="media-chat-item media-chat-system-item chat-message-text"></div>
								<div class="font-size-sm text-muted mt-2 chat-message-timestamp"></div>
							</div>
						</li>`

// component entry point
//   if no cbfunc, means, widget control render stop looking for nested pj-widget
function render(target) {

     pool = $(target);
     $(target).html(tpl);
     // pool.remove();  // disable visible element

     // definitions
     chatDialog = $("#quick-action-panel-chat").find(".chat-dialog");
     chatMsgBox = $("#quick-action-panel-chat").find(".chat-message-box");
     chatTarget = $("#quick-action-panel-chat").find(".chat-message-receipent");

     targetAudience = false;

     initEvents();
     initElements();

}

function refresh() {

     initOnlineUserList();
}

// setup event listener
// register pjEvents
function initEvents(){

     // global event
     $(window).on("usersOnlineStatusChanged", (e) => {initOnlineUserList();});

     utils.registerPJEvent({
          onUserMessage : onMessageReceived,
          onQuickActionTab : onQuickActionTab,
          onOnlineUserClick : onOnlineUserClick            // fire while name is clided from  online user list
     });

     // init event listener
     chatMsgBox.find("button").on("click", onSendMessage);
     chatMsgBox.find("textarea").on("keypress", (e) => {
          // capture enter
          if (e.keyCode === 13 && !e.shiftKey){
               e.preventDefault();
               onSendMessage();
          }
     });
}


function initElements() {
     chatMsgBox.find(".select").select2({
          minimumResultsForSearch: Infinity
     });
}

//   rev 22.08.08
//   handle when user named click from online user list
//   IN : selectedSessSN
function onOnlineUserClick(res) {


     if (res.selectedSessSN) {

          // select user from targetAudience
          initOnlineUserList(()=>{
               changeTargetAudience(res.selectedSessSN);
          });

          // open quickActiveMenu
          utils.triggerPJEvent("openQuickActionMenu", {
               tab : "tab-chat-dialog"
          });
     }
}

//   change target audience
function changeTargetAudience(givenSessSN) {

     var userinfo = utils.getItem("userinfo");
     var targetA = "everyone";

     if (givenSessSN) {
          if (givenSessSN !== userinfo.sessSN) {
               targetA = givenSessSN;
          }
     }

     chatTarget.val(targetA).trigger("change");

}


//   rev 22.08.06
//   handle when a user is clicked on online user
//   pj-event : onQuickActionTab
//
function onQuickActionTab(ctx) {

     // when chat tab open, init online users
     if (ctx.tab == "tab-chat-dialog") {
          initOnlineUserList();
     }
}


function initOnlineUserList(cbfunc){

     var userinfo = utils.getItem("userinfo");

     api.doQuery("getOnlineUsers", {}, function(res){
          // utils.log(res);
          if (res.result) {

               // get last selection
               var lastSelection = chatTarget.val();

               chatTarget.empty()

               $.each(res.data, (idx, u) => {

                    if (u.sessSN !== userinfo.sessSN) {
                         var t = $(`<option value=${u.sessSN}>${u.title}</option>`);
                         chatTarget.append(t);
                    }
               });

               var t = $(`<option value='everyone'>Everyone</option>`);

               chatTarget.prepend(t);

               if (lastSelection == null) {
                    lastSelection = "everyone";
               }

               chatTarget.val(lastSelection).trigger("change");

               if (cbfunc){
                    cbfunc();
               }
          }
     })
}


//   rev 22.08.06
//   send a text message
function onSendMessage(e) {

     var txt = chatMsgBox.find("textarea");
     var targetAudience = chatTarget.val();

     var sanitizeText = utils.sanitizeHTML(txt.val());

     var payload = {
          to : targetAudience,
          message : sanitizeText
     }

     // compose message
     utils.triggerPJEvent("sendUserMessage", payload, (res) => {
          chatMsgBox.find("textarea").val("")
     });

}

//   rev 22.08.08
//   handle while message arrive
//
function onMessageReceived(res) {

     var quickActionMenuStatus = utils.getAppProperty("quickActionMenuStatus");
     var userinfo = utils.getItem("userinfo");

     var bubble;

     // utils.log(">>>>>>>>>>>>", res);

     switch (res.fm.sessSN) {
          case userinfo.sessSN:
               bubble = $(chat_bubble_me_tpl).clone();
               bubble.find(".chat-message-text").html(`<b>Me</b> : ${res.message}`);
               break;
          default:

               var showNotification = false;
               var _res = res;

               if (quickActionMenuStatus == "hidden" || quickActionMenuStatus == undefined) {
                    showNotification = true;
               }

               var messageHtml = `<b>${res.fm.title}</b> : ${res.message}`;
               var notificationType = "message";

               switch (res.type) {
                    case "public":
                         bubble = $(chat_bubble_public_tpl).clone();
                         break;
                    case "system" :
                         bubble = $(chat_bubble_system_tpl).clone();
                         messageHtml = `<b>${res.message}</b>`;
                         notificationType = "info";
                         break;
                    default:
                         bubble = $(chat_bubble_private_tpl).clone();
               }

               if (showNotification) {
                    utils.notification({
                         theme :notificationType,
                         message : `<div>${messageHtml}</div>
                                   <div class='text-right font-size-xs'>${res.time}</div>`,
                         cbfunc : () => {
                              onOnlineUserClick({
                                   selectedSessSN : _res.fm.sessSN
                              });
                         }
                    });
               }


               bubble.find(".chat-message-text").html(messageHtml);
     }

     // add timestamp
     bubble.find(".chat-message-timestamp").html(`${res.time}`);

     // assign data
     bubble.data("senderinfo", res.fm);

     // add bubble click event if this is not a system message
     if (res.type !== "system") {
          bubble.on("click",(e) => {
               var sender = $(e.target).closest("li").data("senderinfo");
               //   console.log(sender);
               changeTargetAudience(sender.sessSN) ;
          });
     }

     $(chatDialog).append(bubble);

}
