import * as utils from "../../js/utilities.js";
import * as api from "../../js/api_conn.js";


import tpl from 'bundle-text:./nav_onlineUsers.tpl';

export {
     render
}


let pool;      // container for this component

const placeholder_img = "../assets/images/placeholder.jpg";

const item_tpl = `<li class="media">
                    <div class="mr-3"><i class='icon-user icon-2x'></i></div>
                    <div class="media-body"><a href="#" class="media-title font-weight-semibold usertitle"></a>
                    <span class="d-block text-muted font-size-sm usergroup"></span></div>
                    <div class="ml-3 align-self-center userstatus"><span class="badge badge-mark border-success"></span></div>
                    </li>`;

const badge_tpl = `<span class="badge badge-warning badge-pill ml-auto ml-lg-0">0</span>
                    <span class="d-lg-none ml-3">Messages</span>`;


// component entry point
function render(target) {

     pool = $(target);
     pool.html(tpl);

     // updateContext();
     initEvents();


     setTimeout(updateContext, 1000);

}

// setup event listener
// register pjEvents
function initEvents(){
     $(window).on("usersOnlineStatusChanged", updateContext);
}

function renderOnlineUsers(arrUserList) {

     var t = pool.find(".pj-onlineUserList")
     var items = t.find("li");
     // clear list
     items.addClass("_pendingRemove");

     for (var idx in arrUserList){

          var user = arrUserList[idx];

          //utils.log(user);

          var e = t.find("li[usersn="+user.sn+"]");

          if (e.length > 0) {
               // already on list
               e.removeClass("_pendingRemove");
          } else {
               // add item
               var item = $(item_tpl).clone();

               item.data("userinfo", user)
               item.find(".usertitle").html(user.title);

               var grouplist = Object.keys(user.groups);
               item.find(".usergroup").html(grouplist.toString());

               // add event
               item.on("click", openUserDialog);

               t.append(item);
          }
     };
     t.find("li._pendingRemove").remove();
}


function openUserDialog(e) {

     var targetUserInfo = $(this).data("userinfo");

     utils.triggerPJEvent("onOnlineUserClick", {          
          selectedSessSN : targetUserInfo.sessSN
     });

}



function updateContext(){

     api.doQuery("getOnlineUsers", {}, function(res){
          // utils.log(res);
          if (res.result) {
               renderOnlineUsers(res.data);
          } else {
               // retry
          }
     })
}
